import { ThemeMode } from '../useThemeStore';

export const color = {
  [ThemeMode.LIGHT]: {
    borderBrandBorderBrandPrimary: '#e23e52',
    borderBrandBorderBrandSecondary: '#972937',
    borderElementsBoderInvert: '#ffffff',
    borderElementsBorderPrimary: '#89919b',
    borderElementsBorderSecondary: '#c8cacc',
    borderElementsBorderSurface: '#02070d',
    borderElementsBorderWeak: '#eaebeb',
    borderSemanticBorderError: '#ac1d2b',
    fillsAccentFillAccentLight: '#b7e1f6',
    fillsAccentFillAccentPrimary: '#27a6e3',
    fillsAccentFillAccentSecondary: '#1a6f97',
    fillsBrandFillBrandLight: '#1f0a0c',
    fillsBrandFillBrandPrimary: '#e23e52',
    fillsBrandFillBrandSecondary: '#e23e52',
    fillsElementsFillHover: '#eaebeb',
    fillsElementsFillInactive: '#f4f4f5',
    fillsElementsFillInvert: '#ffffff',
    fillsElementsFillPrimary: '#02070d',
    fillsElementsFillSecondary: '#414953',
    fillsElementsFillTertiary: '#b2b6bb',
    fillsElementsFillWeak: '#c8cacc',
    fillsSemanticFillError: '#ac1d2b',
    fillsSemanticFillErrorBg: '#fed7d8',
    fillsSemanticFillInfo: '#27a6e3',
    fillsSemanticFillInfoBg: '#b7e1f6',
    fillsSemanticFillSuccess: '#4bbf28',
    fillsSemanticFillSuccessBg: '#ccf0b6',
    fillsSemanticFillWarning: '#db7b06',
    fillsSemanticFillWarningBg: '#fdefcb',
    fillsSurfaceSurfacePrimary: '#f4f4f5',
    fillsSurfaceSurfaceSecondary: '#ffffff',
    fillsSurfaceSurfaceTertiary: '#f9f9fa',
    fillsTransactionsFillBuy: '#1e8914',
    fillsTransactionsFillBuyHover: '#075b0c',
    fillsTransactionsFillSell: '#ac1d2b',
    fillsTransactionsFillSellHover: '#720b15',
    textTextAccentLight: '#b7e1f6',
    textTextAccentPrimary: '#27a6e3',
    textTextAccentSecondary: '#1a6f97',
    textBrandTextBrandLight: '#ec7e8c',
    textBrandTextBrandPrimary: '#e23e52',
    textBrandTextBrandSecondary: '#972937',
    textElementsTextAction: '#ffffff',
    textElementsTextInactive: '#b2b6bb',
    textElementsTextPrimary: '#02070d',
    textElementsTextSecondary: '#303842',
    textElementsTextWeak: '#89919b',
    textSemanticTextErrorPrimary: '#ac1d2b',
    textSemanticTextErrorSecondary: '#ac1d2b',
    textSemanticTextSuccessPrimary: '#4bbf28',
    textSemanticTextSuccessSecondary: '#1e8914',
    textSemanticTextWarningPrimary: '#db7b06',
    textSemanticTextWarningSecondary: '#9d4903',
    orderBookFillBids: '#0e7110', // renders to figma #133614 renderuje się jako #164116
    orderBookFillAsks: '#8c1929', // w figmie jest #3D121A renderuje się jako #4C131B
  },
  [ThemeMode.DARK]: {
    borderBrandBorderBrandPrimary: '#e23e52',
    borderBrandBorderBrandSecondary: '#972937',
    borderElementsBoderInvert: '#ffffff',
    borderElementsBorderPrimary: '#89919b',
    borderElementsBorderSecondary: '#414953',
    borderElementsBorderSurface: '#02070d',
    borderElementsBorderWeak: '#1d252f',
    borderSemanticBorderError: '#ef3b4d',
    fillsAccentFillAccentLight: '#062737',
    fillsAccentFillAccentPrimary: '#27a6e3',
    fillsAccentFillAccentSecondary: '#1a6f97',
    fillsBrandFillBrandLight: '#1f0a0c',
    fillsBrandFillBrandPrimary: '#e23e52',
    fillsBrandFillBrandSecondary: '#972937',
    fillsElementsFillHover: '#1d252f',
    fillsElementsFillInactive: '#1d252f',
    fillsElementsFillInvert: '#02070d',
    fillsElementsFillPrimary: '#ffffff',
    fillsElementsFillSecondary: '#b2b6bb',
    fillsElementsFillTertiary: '#656d77',
    fillsElementsFillWeak: '#414953',
    fillsSemanticFillError: '#ef3b4d',
    fillsSemanticFillErrorBg: '#37060b',
    fillsSemanticFillInfo: '#27a6e3',
    fillsSemanticFillInfoBg: '#062737',
    fillsSemanticFillSuccess: '#4bbf28',
    fillsSemanticFillSuccessBg: '#063709',
    fillsSemanticFillWarning: '#DB8D06',
    fillsSemanticFillWarningBg: '#372506',
    fillsSurfaceSurfacePrimary: '#02070d',
    fillsSurfaceSurfaceSecondary: '#11161c',
    fillsSurfaceSurfaceTertiary: '#171d25',
    fillsTransactionsFillBuy: '#1e8914',
    fillsTransactionsFillBuyHover: '#075b0c',
    fillsTransactionsFillSell: '#ac1d2b',
    fillsTransactionsFillSellHover: '#720b15',
    textTextAccentLight: '#b7e1f6',
    textTextAccentPrimary: '#27a6e3',
    textTextAccentSecondary: '#1a6f97',
    textBrandTextBrandLight: '#ec7e8c',
    textBrandTextBrandPrimary: '#e23e52',
    textBrandTextBrandSecondary: '#972937',
    textElementsTextAction: '#ffffff',
    textElementsTextInactive: '#414953',
    textElementsTextPrimary: '#ffffff',
    textElementsTextSecondary: '#b2b6bb',
    textElementsTextWeak: '#656d77',
    textSemanticTextErrorPrimary: '#ef3b4d',
    textSemanticTextErrorSecondary: '#ac1d2b',
    textSemanticTextSuccessPrimary: '#4bbf28',
    textSemanticTextSuccessSecondary: '#1e8914',
    textSemanticTextWarningPrimary: '#db7b06',
    textSemanticTextWarningSecondary: '#9d4903',
    orderBookFillBids: '#0e7110', // renders to figma #133614 renderuje się jako #164116
    orderBookFillAsks: '#8c1929', // w figmie jest #3D121A renderuje się jako #4C131B
  },
};
